import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./footer.css"

const Footer = ({ siteTitle }) => {

    return(
        <footer>
            <div className="copyright"><span>© 2021 keirizaimu.com. All RIght reseved.</span>　・　<Link to="/privacy-policy">Privacy Policy</Link></div>
        </footer>
    );
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
}

export default Footer
