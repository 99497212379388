module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"経理・財務のスキルアップ応援ブログ | keirizaimu.com","short_name":"keirizaimu","description":"経理・財務の仕事や資格などスキルアップに役立つ情報を発信。大企業（製造業）の経理で10年以上、工場・本社・海外と各拠点を経理目線で仕事をしてきて気づいたことをブログにしていきます。","start_url":"/","background_color":"#3E3E3E","theme_color":"#3E3E3E","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"bad5447c5c4ca70d652480b4eae258a4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-NZZCT28","includeInDevelopment":false,"defaultDataLayer":null,"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
