import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "./header.css"

const Header = ({ siteTitle }) => (
  <header>
    <Link
      to="/"
      className="logo"
      aria-label="keirizaimu.com"
    >
    <svg width="240" height="25" viewBox="0 0 240 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.86533 9.71142C5.86533 10.4047 5.83479 11.1693 5.77369 12.0054C5.71259 12.8414 5.63113 13.606 5.5293 14.2993H5.65149C5.87552 13.9527 6.16064 13.5143 6.50686 12.9841C6.87344 12.4539 7.18911 12.0156 7.45386 11.6689L11.2419 6.89753H17.7487L11.8834 14.0852L18.1153 23.8115H11.4557L7.75935 17.7555L5.86533 19.193V23.8115H0V0.566276H5.86533V9.71142Z" fill="#4543DE"/>
      <path d="M27.7436 6.59167C30.2079 6.59167 32.1528 7.23397 33.5784 8.51858C35.004 9.80318 35.7168 11.7403 35.7168 14.3299V16.9297H25.2997C25.3405 17.8065 25.6663 18.5303 26.2773 19.1013C26.9086 19.6722 27.8149 19.9577 28.9961 19.9577C30.0551 19.9577 31.0225 19.8557 31.8983 19.6518C32.774 19.4479 33.6803 19.1216 34.6171 18.6731V22.8633C33.8024 23.2915 32.9064 23.6076 31.9288 23.8115C30.9716 24.0154 29.7598 24.1173 28.2935 24.1173C26.5828 24.1173 25.0554 23.8217 23.7112 23.2303C22.3874 22.6186 21.3386 21.6705 20.5647 20.3859C19.7908 19.1013 19.4039 17.4598 19.4039 15.4615C19.4039 13.4225 19.7501 11.7505 20.4425 10.4455C21.1553 9.14049 22.1329 8.17194 23.3752 7.53983C24.6379 6.90773 26.094 6.59167 27.7436 6.59167ZM27.9575 10.5678C27.265 10.5678 26.6846 10.7819 26.2162 11.2101C25.7682 11.6383 25.5034 12.3214 25.4219 13.2594H30.4319C30.4115 12.5049 30.1875 11.8728 29.7598 11.3631C29.3525 10.8329 28.7517 10.5678 27.9575 10.5678Z" fill="#4543DE"/>
      <path d="M41.9843 0.0769043C42.8193 0.0769043 43.5423 0.250223 44.1533 0.596861C44.7846 0.9435 45.1003 1.63678 45.1003 2.67669C45.1003 3.67583 44.7846 4.35891 44.1533 4.72594C43.5423 5.07258 42.8193 5.2459 41.9843 5.2459C41.1289 5.2459 40.3958 5.07258 39.7848 4.72594C39.1942 4.35891 38.8989 3.67583 38.8989 2.67669C38.8989 1.63678 39.1942 0.9435 39.7848 0.596861C40.3958 0.250223 41.1289 0.0769043 41.9843 0.0769043ZM44.8864 6.89753V23.8115H39.0516V6.89753H44.8864Z" fill="#4543DE"/>
      <path d="M59.1586 6.59167C59.4844 6.59167 59.8205 6.61206 60.1667 6.65284C60.5129 6.69363 60.7675 6.73441 60.9304 6.77519L60.4111 12.2806C60.2074 12.2398 59.9529 12.1991 59.6474 12.1583C59.3623 12.1175 58.9549 12.0971 58.4254 12.0971C57.9366 12.0971 57.4071 12.1685 56.8369 12.3112C56.287 12.4539 55.8084 12.77 55.4011 13.2594C54.9938 13.7284 54.7901 14.4726 54.7901 15.4921V23.8115H48.9554V6.89753H53.2933L54.2097 9.58908H54.4847C54.9531 8.75307 55.6048 8.0496 56.4398 7.47866C57.2951 6.88734 58.2014 6.59167 59.1586 6.59167Z" fill="#4543DE"/>
      <path d="M66.2382 0.0769043C67.0732 0.0769043 67.7962 0.250223 68.4072 0.596861C69.0385 0.9435 69.3542 1.63678 69.3542 2.67669C69.3542 3.67583 69.0385 4.35891 68.4072 4.72594C67.7962 5.07258 67.0732 5.2459 66.2382 5.2459C65.3829 5.2459 64.6497 5.07258 64.0387 4.72594C63.4481 4.35891 63.1528 3.67583 63.1528 2.67669C63.1528 1.63678 63.4481 0.9435 64.0387 0.596861C64.6497 0.250223 65.3829 0.0769043 66.2382 0.0769043ZM69.1404 6.89753V23.8115H63.3056V6.89753H69.1404Z" fill="#4543DE"/>
      <path d="M85.9175 23.8115H71.9262V20.4776L78.9219 11.3631H72.3234V6.89753H85.5815V10.5067L78.8913 19.3459H85.9175V23.8115Z" fill="#4543DE"/>
      <path d="M96.9405 6.59167C99.0789 6.59167 100.749 7.12183 101.951 8.18213C103.152 9.24244 103.753 10.7513 103.753 12.7088V23.8115H99.7205L98.5902 21.5787H98.468C97.9996 22.17 97.5108 22.6594 97.0016 23.0468C96.5129 23.4139 95.9426 23.6789 95.2909 23.8421C94.6392 24.0256 93.845 24.1173 92.9081 24.1173C91.4418 24.1173 90.2199 23.6687 89.2423 22.7715C88.2647 21.8744 87.776 20.498 87.776 18.6425C87.776 16.8277 88.3971 15.4819 89.6394 14.6051C90.9021 13.7284 92.7248 13.239 95.1076 13.137L97.9181 13.0453V12.8006C97.9181 12.0461 97.7348 11.516 97.3682 11.2101C97.0016 10.8839 96.5027 10.7208 95.8713 10.7208C95.1993 10.7208 94.4559 10.8431 93.6413 11.0878C92.8267 11.3121 92.0019 11.6077 91.1669 11.9748L89.4867 8.12096C90.4642 7.6112 91.564 7.23397 92.7859 6.98929C94.0283 6.72421 95.4131 6.59167 96.9405 6.59167ZM96.6045 16.3179C95.5455 16.3587 94.792 16.5524 94.3439 16.8991C93.9162 17.2253 93.7024 17.6943 93.7024 18.306C93.7024 18.877 93.8551 19.3052 94.1606 19.5906C94.4661 19.8557 94.8734 19.9882 95.3826 19.9882C96.0954 19.9882 96.6962 19.7639 97.1849 19.3154C97.6941 18.8668 97.9487 18.2856 97.9487 17.572V16.2568L96.6045 16.3179Z" fill="#4543DE"/>
      <path d="M110.689 0.0769043C111.524 0.0769043 112.247 0.250223 112.858 0.596861C113.489 0.9435 113.805 1.63678 113.805 2.67669C113.805 3.67583 113.489 4.35891 112.858 4.72594C112.247 5.07258 111.524 5.2459 110.689 5.2459C109.834 5.2459 109.1 5.07258 108.489 4.72594C107.899 4.35891 107.603 3.67583 107.603 2.67669C107.603 1.63678 107.899 0.9435 108.489 0.596861C109.1 0.250223 109.834 0.0769043 110.689 0.0769043ZM113.591 6.89753V23.8115H107.756V6.89753H113.591Z" fill="#4543DE"/>
      <path d="M138.28 6.59167C140.215 6.59167 141.691 7.08105 142.71 8.05979C143.728 9.03854 144.237 10.6188 144.237 12.8006V23.8115H138.402V14.6051C138.402 13.3205 138.229 12.4336 137.883 11.9442C137.537 11.4344 137.028 11.1795 136.356 11.1795C135.419 11.1795 134.767 11.5975 134.401 12.4336C134.034 13.2492 133.851 14.4114 133.851 15.9203V23.8115H128.016V14.6051C128.016 13.3817 127.853 12.5049 127.527 11.9748C127.201 11.4446 126.712 11.1795 126.061 11.1795C125.063 11.1795 124.381 11.6281 124.014 12.5253C123.668 13.4225 123.495 14.7173 123.495 16.4097V23.8115H117.66V6.89753H122.059L122.914 8.97736H123.067C123.515 8.28409 124.157 7.71315 124.992 7.26456C125.847 6.81597 126.896 6.59167 128.138 6.59167C129.38 6.59167 130.399 6.80577 131.193 7.23397C132.008 7.64178 132.669 8.21272 133.179 8.94678H133.362C133.891 8.19233 134.574 7.6112 135.409 7.20339C136.264 6.79558 137.221 6.59167 138.28 6.59167Z" fill="#4543DE"/>
      <path d="M164.644 6.89753V23.8115H160.245L159.512 21.701H159.175C158.646 22.5574 157.923 23.1794 157.007 23.5668C156.09 23.9338 155.113 24.1173 154.074 24.1173C152.974 24.1173 151.976 23.9032 151.08 23.475C150.184 23.0468 149.471 22.3739 148.942 21.4564C148.433 20.5388 148.178 19.3561 148.178 17.9084V6.89753H154.013V16.1344C154.013 17.2355 154.166 18.0715 154.471 18.6425C154.797 19.2134 155.316 19.4989 156.029 19.4989C157.129 19.4989 157.862 19.0503 158.228 18.1531C158.615 17.2559 158.809 15.9713 158.809 14.2993V6.89753H164.644Z" fill="#4543DE"/>
      <path d="M167.941 21.334C167.941 20.2737 168.246 19.5295 168.857 19.1013C169.488 18.6731 170.242 18.459 171.118 18.459C171.953 18.459 172.665 18.6731 173.256 19.1013C173.867 19.5295 174.172 20.2737 174.172 21.334C174.172 22.3332 173.867 23.057 173.256 23.5056C172.665 23.9542 171.953 24.1785 171.118 24.1785C170.242 24.1785 169.488 23.9542 168.857 23.5056C168.246 23.057 167.941 22.3332 167.941 21.334Z" fill="#4543DE"/>
      <path d="M185.202 24.1173C182.636 24.1173 180.59 23.424 179.062 22.0375C177.535 20.6509 176.771 18.4488 176.771 15.431C176.771 13.3715 177.158 11.6995 177.932 10.4149C178.706 9.1099 179.755 8.15155 181.078 7.53983C182.423 6.90773 183.95 6.59167 185.661 6.59167C186.699 6.59167 187.677 6.70382 188.593 6.92812C189.53 7.15241 190.396 7.46847 191.19 7.87628L189.479 12.1889C188.787 11.883 188.135 11.6383 187.524 11.4548C186.934 11.2713 186.312 11.1795 185.661 11.1795C184.785 11.1795 184.072 11.5262 183.522 12.2195C182.972 12.9127 182.697 13.973 182.697 15.4004C182.697 16.8685 182.972 17.9186 183.522 18.5507C184.092 19.1624 184.815 19.4683 185.691 19.4683C186.526 19.4683 187.361 19.3357 188.196 19.0707C189.052 18.8056 189.856 18.4386 190.61 17.9696V22.6186C189.917 23.0672 189.133 23.4342 188.257 23.7197C187.382 23.9848 186.363 24.1173 185.202 24.1173Z" fill="#4543DE"/>
      <path d="M210.101 15.3086C210.101 18.1429 209.348 20.3247 207.841 21.854C206.334 23.3629 204.267 24.1173 201.639 24.1173C200.01 24.1173 198.564 23.7809 197.302 23.108C196.039 22.4147 195.041 21.4156 194.308 20.1106C193.595 18.7852 193.239 17.1845 193.239 15.3086C193.239 12.5151 193.992 10.3639 195.499 8.85502C197.006 7.34612 199.084 6.59167 201.731 6.59167C203.36 6.59167 204.796 6.92812 206.038 7.601C207.301 8.27389 208.289 9.26283 209.002 10.5678C209.735 11.8524 210.101 13.4327 210.101 15.3086ZM199.165 15.3086C199.165 16.7563 199.359 17.8676 199.745 18.6425C200.132 19.3969 200.784 19.7741 201.701 19.7741C202.597 19.7741 203.228 19.3969 203.595 18.6425C203.982 17.8676 204.175 16.7563 204.175 15.3086C204.175 13.8609 203.982 12.77 203.595 12.0359C203.228 11.3019 202.587 10.9349 201.67 10.9349C200.794 10.9349 200.153 11.3019 199.745 12.0359C199.359 12.77 199.165 13.8609 199.165 15.3086Z" fill="#4543DE"/>
      <path d="M234.043 6.59167C235.978 6.59167 237.454 7.08105 238.473 8.05979C239.491 9.03854 240 10.6188 240 12.8006V23.8115H234.165V14.6051C234.165 13.3205 233.992 12.4336 233.646 11.9442C233.3 11.4344 232.791 11.1795 232.118 11.1795C231.182 11.1795 230.53 11.5975 230.163 12.4336C229.797 13.2492 229.613 14.4114 229.613 15.9203V23.8115H223.779V14.6051C223.779 13.3817 223.616 12.5049 223.29 11.9748C222.964 11.4446 222.475 11.1795 221.824 11.1795C220.826 11.1795 220.143 11.6281 219.777 12.5253C219.431 13.4225 219.257 14.7173 219.257 16.4097V23.8115H213.423V6.89753H217.822L218.677 8.97736H218.83C219.278 8.28409 219.919 7.71315 220.754 7.26456C221.61 6.81597 222.659 6.59167 223.901 6.59167C225.143 6.59167 226.161 6.80577 226.956 7.23397C227.77 7.64178 228.432 8.21272 228.941 8.94678H229.125C229.654 8.19233 230.336 7.6112 231.171 7.20339C232.027 6.79558 232.984 6.59167 234.043 6.59167Z" fill="#4543DE"/>
    </svg>


    </Link>
    <nav>
      <Link to="/">ホーム</Link>
    </nav>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
